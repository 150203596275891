import React, { useEffect, useState } from "react";
import Header from "../Common/Header";

import Footer from "../Common/Footer";
import { apiurl } from "../url";

const Howitworks = () => {
  const [stop, setStop] = useState(true);
  const [data, setData] = useState("");

  useEffect(() => {
    if (stop === true) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };

      const request = new Request(`${apiurl}/api/how_it_works`, requestOptions);

      fetch(request)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((result) => {
          // console.log(result.howItWorks.details, "test data");
          setData(result);
          setStop(false);
    
        })
        .catch((error) => {
          // console.error("Error fetching data:", error);
        });
    }
  }, [stop]);

  return (
    <>
         <Header />
         <div className="faq-banner_section">
    <div className="faqbanner_content">
     
            <h1 className="t-h2">How it Works</h1>
           
    </div>
</div>
<div className="faq-contianer">
   
        <div className="abouhome_container faq">
      

          <ol
            dangerouslySetInnerHTML={{
              __html: data.howItWorks ? data.howItWorks.details : "",
            }}
          ></ol>
        </div>

      </div>
      <Footer />
    </>
  );
};

export default Howitworks;
